// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$.jsx");
  import.meta.hot.lastModified = "1721379226705.4478";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { useLoaderData, redirect } from "@remix-run/react";
import { languages } from "~/utils/langs";
import { isPreview } from "~/utils/isPreview";
import { getStoryblokApi, useStoryblokState, StoryblokComponent } from "@storyblok/react";
export const action = async ({
  request
}) => {
  // 1. Handle form data or other POST payload
  const formData = await request.formData();

  // 2. Process data (e.g., save to a database, perform validation)

  // 3. Redirect to a success page
  return redirect("/success-page");
};
export default function Page() {
  _s();
  let {
    story
  } = useLoaderData();
  story = useStoryblokState(story, {
    resolveRelations: ["default-page"]
  });
  return <>
      <StoryblokComponent blok={story.content} />
    </>;
}
_s(Page, "1Jorwn4qQWGDlzJXbL4XZ9FVGow=", false, function () {
  return [useLoaderData, useStoryblokState];
});
_c = Page;
export const loader = async ({
  params,
  request
}) => {
  let slug = params["*"] ?? "home";
  let blogSlug = params["*"] === "blog/" ? "blog/home" : null;

  // Extract the language from the URL
  let url = new URL(request.url);
  let pathParts = url.pathname.split("/");
  let language = pathParts[1];

  // If the language is not one of the supported languages, it's 'en' and the first part of the URL is part of the slug

  if (!languages.includes(language)) {
    language = "en";
  } else {
    // Remove the language part from the slug
    if (pathParts[0] === "") {
      pathParts.shift(); // Remove the first empty string from the array
    }
    pathParts.shift(); // Remove the language part from the array
  }
  slug = pathParts.join("/") || slug;
  slug = slug === "/" || slug === language ? "home" : slug;
  slug = blogSlug ? blogSlug : slug;
  let version = isPreview() ? "draft" : "published";
  let sbParams = {
    version,
    resolve_relations: ["popular-articles.articles"],
    language
  };
  const {
    data
  } = await getStoryblokApi().get(`cdn/stories/${slug}`, sbParams, {
    cache: "no-store"
  }).catch(e => {
    console.log("e", e);
    return {
      data: null
    };
  });
  if (!data) {
    throw new Response("Not Found", {
      status: 404
    });
  }
  let {
    data: articles
  } = await getStoryblokApi().get(`cdn/stories`, {
    version,
    starts_with: "blog/",
    language,
    is_startpage: 0
  }, {
    cache: "no-store"
  });
  //console.log("A:",data);
  return json({
    story: data?.story,
    articles: articles?.stories
  });
};
var _c;
$RefreshReg$(_c, "Page");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;